// src/components/FeaturedProducts.js
import React, { useEffect, useState } from "react";
import { db } from '../config/firebaseConfig'; 
import { collection, getDocs } from 'firebase/firestore';

const FeaturedProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const calculateDiscount = (original, discounted) => {
    return Math.round(((original - discounted) / original) * 100);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsCollection = collection(db, 'products'); // Collection name in Firestore
        const productSnapshot = await getDocs(productsCollection);
        const productList = productSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })); // Include id if needed
        setProducts(productList);
      } catch (err) {
        setError('Error fetching products: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  if (loading) {
    return (
      <section id="featured-products" className="featured-products">
        <div className="container">
          <h2>Featured Products</h2>
          <div className="loading-indicator">
            {/* Simple loading dots */}
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </div>
        </div>
      </section>
    );
  }

  if (error) {
    return (
      <section id="featured-products" className="featured-products">
        <div className="container">
          <h2>Featured Products</h2>
          <p>{error}</p>
        </div>
      </section>
    );
  }

  return (
    <section id="featured-products" className="featured-products">
      <div className="container">
        <h2>Featured Products</h2>
        <div id="product-grid" className="product-grid">
          {products.map((product) => (
            <div key={product.id} className="product-card">
              <img src={product.image} alt={product.title} className="product-image" />
              <div className="product-info">
                <h3 className="product-title">{product.title}</h3>
                <div className="product-price">
                  <span className="original-price">{product.originalPrice.toFixed(2)} TND</span>
                  <span className="discounted-price">{product.discountedPrice.toFixed(2)} TND</span>
                  <span className="discount-label">-{calculateDiscount(product.originalPrice, product.discountedPrice)}%</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturedProducts;




// import React from "react";

// const products = [
//     { title: "SHEIN Robe caraco à superposition en maille", originalPrice: 120, discountedPrice: 78,  image: "./img/28285030.png" },
//     { title: "Robe Lingerie Babydoll", originalPrice: 85, discountedPrice: 70,  image: "./img/29638984.png" },
//     { title: "Ensemble de lingerie en dentelle bicolore 5 pièces", originalPrice: 110, discountedPrice: 95,  image: "./img/38637453.png" },
//     { title: "SHEIN DelicateAllure Robe babydoll asymétrique en maille avec string", originalPrice: 49, discountedPrice: 39,  image: "./img/472894.png" },
//     { title: "Chemise de nuit DAZY ornée de patchwork de maille et de dentelle", originalPrice: 110, discountedPrice: 94.900,  image: "./img/32879842.png" },
//     { title: "Déshabillé combiné en dentelle romantique 2 pièces avec ourlet asymétrique à volants", originalPrice: 105, discountedPrice: 88,  image: "./img/36385890.png" },
//     { title: "Robe de lingerie en dentelle à motif cœur", originalPrice: 85, discountedPrice: 79,  image: "./img/32048985.png" },
//     { title: "Ensemble de lingerie classique", originalPrice: 69, discountedPrice: 65,  image: "./img/5052232.png" },
//   ];

// const FeaturedProducts = () => {
//   const calculateDiscount = (original, discounted) => {
//     return Math.round(((original - discounted) / original) * 100);
//   };

//   return (
//     <section id="featured-products" className="featured-products">
//       <div className="container">
//         <h2>Featured Products</h2>
//         <div id="product-grid" className="product-grid">
//           {products.map((product, index) => (
//             <div key={index} className="product-card">
//               <img src={product.image} alt={product.title} className="product-image" />
//               <div className="product-info">
//                 <h3 className="product-title">{product.title}</h3>
//                 <div className="product-price">
//                   <span className="original-price">{product.originalPrice.toFixed(2)} TND</span>
//                   <span className="discounted-price">{product.discountedPrice.toFixed(2)} TND</span>
//                   <span className="discount-label">-{calculateDiscount(product.originalPrice, product.discountedPrice)}%</span>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default FeaturedProducts;
