import React from "react";
import { useTranslation } from "react-i18next";
//import "./Hero.css"; // Assuming there's a separate CSS file for styling

const Hero = () => {
  const { t } = useTranslation(); // Access the translation function from i18next

  return (
    <section id="home" className="hero">
      <div className="hero-content">
        <div className="hero-text">
          <h1>{t("hero-title")}</h1> 
          <p>{t("hero-subtitle")}</p> 
          <a href="/track" className="cta-button">
            {t('nav-track')} 
          </a>
          {/* <a href="#products" className="cta-button">
            {t("hero-cta")} 
          </a> */}
        </div>
        <div className="hero-slideshow">
          <iframe
            src="https://snapwidget.com/embed/1079323"
            className="snapwidget-widget"
            allowTransparency="true"
            frameBorder="0"
            scrolling="no"
            title="Posts from Instagram"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default Hero;
