import React from "react";
import { useTranslation } from 'react-i18next';
import Header from "./components/Header";
import Hero from "./components/Hero";
import FeaturedProducts from "./components/FeaturedProducts";
import AboutSection from "./components/AboutSection";
import InstagramSection from "./components/InstagramSection";
import ContactForm from "./components/ContactForm";
import Footer from "./components/Footer";
import "./Home.css";
import 'bootstrap/dist/css/bootstrap.min.css';

function Home() {
  const { t, i18n } = useTranslation();

  const updateLanguage = (lang) => {
    i18n.changeLanguage(lang);
    document.documentElement.lang = lang;
    document.documentElement.dir = lang === "ar" ? "rtl" : "ltr";
  };

  return (
    <div className="Home">
      <Header updateLanguage={updateLanguage} />
      
      <div style={{ paddingTop: "100px" }}>
        <Hero />
        <FeaturedProducts />
        <AboutSection />
        <InstagramSection />
        <ContactForm />
      </div>
      
  
    </div>
  );
}

export default Home;
