import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { FaGlobe } from 'react-icons/fa'; // Globe icon from Font Awesome

const Header = ({ updateLanguage }) => {
  const { t } = useTranslation();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const handleLanguageChange = (lang) => {
    updateLanguage(lang);
    setDropdownVisible(false); // Hide the dropdown after selection
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="bg-purple-100 py-4">
      <div className="container mx-auto px-4">
        <nav className="flex items-center justify-between">
          <a href="/" className="logo text-2xl font-bold text-purple-700 hover:text-purple-900 transition duration-300">Moonlit Bloom</a>
          <ul className="flex space-x-6">
            <li>
              <a href="#home" className="text-purple-600 hover:text-purple-800 transition duration-300">
                {t('nav-home')}
              </a>
            </li>
            <li>
              <a href="#about" className="text-purple-600 hover:text-purple-800 transition duration-300">
                {t('nav-about')}
              </a>
            </li>
            <li>
              <a href="#instagram" className="text-purple-600 hover:text-purple-800 transition duration-300">
                {t('nav-instagram')}
              </a>
            </li>
          <div className="relative" ref={dropdownRef}>
            <FaGlobe 
              className="text-purple-600 cursor-pointer hover:text-purple-800 transition duration-300" 
              onClick={() => setDropdownVisible(!dropdownVisible)} 
            />
            {dropdownVisible && (
              <div className="absolute right-0 mt-2 w-32 bg-white border border-purple-300 rounded-lg shadow-lg z-10">
                <ul className="py-1">
                  <li>
                    <button 
                      onClick={() => handleLanguageChange('en')} 
                      className="block px-4 py-2 text-purple-700 hover:bg-purple-100 w-full text-left"
                    >
                      English
                    </button>
                  </li>
                  <li>
                    <button 
                      onClick={() => handleLanguageChange('fr')} 
                      className="block px-4 py-2 text-purple-700 hover:bg-purple-100 w-full text-left"
                    >
                      Français
                    </button>
                  </li>
                  <li>
                    <button 
                      onClick={() => handleLanguageChange('ar')} 
                      className="block px-4 py-2 text-purple-700 hover:bg-purple-100 w-full text-left"
                    >
                      العربية
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
