import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import './App.css';
import Home from './Home';
import AddProduct from './admin/AddProduct';
import PackageTracker from './components/PackageTracker';
import AddOrderTracking from './components/orders/AddOrderTracking';
import AddOrder from './components/orders/AddOrder';
import AdminLogin from './admin/AdminLogin';
import { useEffect } from 'react';
const App = ({ logPageView }) => {
  useEffect(() => {
    logPageView(); // Log the initial page view
  }, [logPageView]);
  return (
    <Router>
      <div className="App">
        {/* Always visible content */}
        {/* Any content you want to show on all routes can also be added here */}
        
        {/* Routes for different pages */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/add" element={<AddProduct />} />
          <Route path="/track" element={<PackageTracker />} />
          <Route path="/add-track" element={<AddOrderTracking />} />
          <Route path="/add-order" element={<AddOrder />} />
          <Route path="/auth" element={<AdminLogin />} />
        </Routes>

        {/* Footer is now always visible */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
