// src/components/AddProduct.js
import React, { useState } from 'react';
import { db, storage } from '../config/firebaseConfig'; 
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const AddProduct = () => {
  const [title, setTitle] = useState('');
  const [originalPrice, setOriginalPrice] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [category, setCategory] = useState('Lingerie'); // Default category
  const [message, setMessage] = useState('');

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!imageFile) {
      setMessage('Please select an image to upload.');
      return;
    }

    // Create a storage reference
    const imageRef = ref(storage, `products/${imageFile.name}`);
    
    try {
      // Upload the image
      await uploadBytes(imageRef, imageFile);
      const imageUrl = await getDownloadURL(imageRef);

      // Add product to Firestore
      const docRef = await addDoc(collection(db, "products"), {
        title,
        originalPrice: parseFloat(originalPrice),
        discountedPrice: parseFloat(discountedPrice),
        category, // Add category to the document
        image: imageUrl,
      });

      setMessage(`Product added with ID: ${docRef.id}`);
      // Reset form
      setTitle('');
      setOriginalPrice('');
      setDiscountedPrice('');
      setImageFile(null);
      setCategory('Lingerie'); // Reset category to default
    } catch (error) {
      setMessage(`Error adding product: ${error.message}`);
    }
  };

  return (
    <div className="container">
      <h2>Add New Product</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Title</label>
          <input type="text" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} required />
        </div>
        <div className="mb-3">
          <label className="form-label">Original Price (TND)</label>
          <input type="number" className="form-control" value={originalPrice} onChange={(e) => setOriginalPrice(e.target.value)} required />
        </div>
        <div className="mb-3">
          <label className="form-label">Discounted Price (TND)</label>
          <input type="number" className="form-control" value={discountedPrice} onChange={(e) => setDiscountedPrice(e.target.value)} required />
        </div>
        <div className="mb-3">
          <label className="form-label">Category</label>
          <select className="form-select" value={category} onChange={(e) => setCategory(e.target.value)}>
            <option value="Lingerie">Lingerie</option>
            <option value="Sleepwear">Sleepwear</option>
            <option value="Bodysuits">Bodysuits</option>
            <option value="Accessories">Accessories</option>
          </select>
        </div>
        <div className="mb-3">
          <label className="form-label">Image</label>
          <input type="file" className="form-control" accept="image/*" onChange={handleImageChange} required />
        </div>
        <button type="submit" className="btn btn-primary">Add Product</button>
      </form>
      {message && <p className="mt-3">{message}</p>}
    </div>
  );
};

export default AddProduct;
