import React from "react";
import { useTranslation } from "react-i18next";

const AboutSection = () => {
  const { t } = useTranslation(); 

  return (
    <section id="about" className="about-section">
      <div className="container">
        <h2>{t("about-title")}</h2>
        <p>{t("about-content")}</p> &
      </div>
    </section>
  );
};

export default AboutSection;
