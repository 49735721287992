import React from "react";
import { useTranslation } from "react-i18next";

const ContactForm = () => {
  const { t } = useTranslation(); // Access the translation function

  return (
    <section id="contact" className="contact-section">
      <div className="container">
        <h2>{t("contact-title")}</h2> {/* Translate contact title */}
        <form action="submit_form.php" method="post">
          <label htmlFor="name">{t("contact-name")}</label> {/* Translate name label */}
          <input type="text" id="name" name="name" required />
          <label htmlFor="email">{t("contact-email")}</label> {/* Translate email label */}
          <input type="email" id="email" name="email" required />
          <label htmlFor="message">{t("contact-message")}</label> {/* Translate message label */}
          <textarea id="message" name="message" required></textarea>
          <button type="submit">{t("contact-submit")}</button> {/* Translate submit button */}
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
